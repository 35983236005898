/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react'
import { Flex, jsx } from 'theme-ui'

import useSocialMediaLinks from '../../hooks/useSocialMediaLinks'

import {
  FaceBookSVG,
  TwitterSVG,
  LinkedinSVG,
  InstagramSVG,
} from '../../components/SocialMediaIcon'

const socialMediaIconMapper = {
  facebook: FaceBookSVG,
  twitter: TwitterSVG,
  linkedIn: LinkedinSVG,
  instagram: InstagramSVG,
}

const Footer = () => {
  const data = useSocialMediaLinks()
  const socialLinks = data?.site?.siteMetadata?.social || []
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      {socialLinks.map((platform) => {
        const Icon = socialMediaIconMapper[platform.name]
        return (
          <Fragment key={platform.name}>
            {Icon && (
              <a href={platform.url} target="_blank" rel="noopener noreferrer">
                <Icon />
              </a>
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default Footer
