/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import BackButton from '../../components/BackButton'
import TextBlock from '../../components/TextBlock'
import Layout from './layout'
import SEO from './seo'
import PostsFooter from './home-footer'
import PostList from './post-list'
import useBlogPostData from '../../hooks/useBlogPostData'

const Posts = () => {
  const posts = useBlogPostData()
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Blogs" />
      <Flex
        sx={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 4,
        }}
      >
        <Box mb={4}>
          <BackButton />
        </Box>
        <Box pl={[2, 2, 4, 4]}>
          <TextBlock
            heading={t('Articles:latestArticles')}
            headingAsH1={true}
          />
        </Box>
        <PostList posts={posts} />
      </Flex>
      <Box p={4}>
        <PostsFooter />
      </Box>
    </Layout>
  )
}

export default Posts
