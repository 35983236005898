/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react'
import { Flex, jsx } from 'theme-ui'
import { Helmet } from 'react-helmet'
import { SkipNavContent } from '@reach/skip-nav'
import useBlogThemeConfig from '../hooks/configOptions'

import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'

const Layout = ({ children }) => {
  const blogThemeConfig = useBlogThemeConfig()
  const { webfontURL } = blogThemeConfig
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href={webfontURL} />
      </Helmet>
      <SkipNavContent />
      <NavBar />
      <Flex
        bg="lynxWhite50"
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: [0, 0, '60px', '60px'],
          paddingX: [2, 4, 4, '50px'],
        }}
      >
        {children}
      </Flex>
      <Footer />
    </>
  )
}

export default Layout
