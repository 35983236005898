/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react'
import { jsx } from 'theme-ui'

import Facebook from '../../assets/svgs/facebook.svg'
import Twitter from '../../assets/svgs/twitter.svg'
import Linkedin from '../../assets/svgs/linkedin.svg'
import Instagram from '../../assets/svgs/instagram.svg'

export const FaceBookSVG = () => (
  <Facebook
    sx={{
      margin: 2,
      width: 30,
      height: 20,
      path: { fill: 'facebook' },
    }}
  />
)

export const TwitterSVG = () => (
  <Twitter
    sx={{
      margin: 2,
      width: 30,
      height: 30,
      path: { fill: 'twitter' },
    }}
  />
)

export const LinkedinSVG = () => (
  <Linkedin
    sx={{
      margin: 2,
      width: 30,
      height: 30,
      path: { fill: 'linkedin' },
    }}
  />
)

export const InstagramSVG = () => (
  <Instagram
    sx={{
      margin: 2,
      width: 30,
      height: 30,
      path: { fill: 'instagram' },
    }}
  />
)
