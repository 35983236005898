import { useStaticQuery, graphql } from 'gatsby'

const useSocialMediaLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          social {
            name
            url
          }
        }
      }
    }
  `)

  return data
}

export default useSocialMediaLinks
